import React from "react";
import Modal from 'react-modal';
import ViewProduct from "../ViewProduct";

function ProductDetailModal(props) {

  const { productName, updateCart, nummer, modalIsOpen, closeModal } = props;
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel={productName}
      appElement={document.getElementById('root') || undefined}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.75)',
          zIndex: 1000
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '4px',
          outline: 'none',
          maxHeight: '90vh',
          maxWidth: '50vw',
        }
      }}
    >
      <button onClick={closeModal} style={{ float: 'right', background: 'none', fontWeight: 'bold', border: '0' }}>Stäng</button>
      <ViewProduct
        productID={nummer}
        updateCart={updateCart}

        buyProductPiece={props.buyProductPiece} 
        updateProductAmount={props.updateProductAmount}
      />
    </Modal>
  );
}

export default ProductDetailModal;