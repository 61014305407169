import React from 'react';
import axios from './bin/axios_gsh';
import FavButton from './components/FavButton';
import AmountForm from './components/AmountForm';
import history from './components/BrowserHistory';
import DisplayError from './components/DisplayError.js';
import Modal from './components/Modal.js';


class ViewProduct extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            productID: this.props.productID,
            itemnnr: 0,
            active: true,
            name: '',
            category: '',
            priceParcel: 0,
            otherUnit: '',
            pricePerOne: 0,
            sizeOfParcel: '',
            sizeOptions: 1,
            extraChargeOnSmallUnit: '',
            unitsize: 0,
            origin: '',
            vat: 0, 
            info: '', 
            fav: 0,
            URL_img: '',
            notDividable: false,
            canBuyPieces: false,
            kolli: 3, 
            st: 2, 
            kg: 1,
            filter: [
                {name: 'Eko', checked: false},
                {name: 'Krav', checked: false}, 
                {name: 'Närodlat', checked: false},
                {name: 'Svenskt', checked: false} 
            ],
            infoMessage: '',
            showInfoBox: false,
            error: null,
            hasLoaded: false,
            showErrorModal: false,
            beskrivning: '',
            innehållsförteckning: '',
        }

        this.updateFav = this.updateFav.bind(this);
        this.addProduct = this.addProduct.bind(this);
        this.informAboutAddedProduct = this.informAboutAddedProduct.bind(this);
        this.setCategory = this.setCategory.bind(this);
        this.setFilter = this.setFilter.bind(this);
        this.update = this.update.bind(this);
    }

    componentDidMount() {
        this.update();
    }

    update(){
        let self = this;
        axios.get('product/' + self.props.productID).then(function (response) {
            let result = response.data;
            if(!result.error) {
                let product = result[0];
                let sizeofParcel = /*(product.Pris_per_styck)? */product.Förpackningsstorlek;// : product.Vikt_per_enhet;
                let sizeofParcelShow = (product.Pris_per_styck)? product.Förpackningsstorlek + ' st': product.Förpackningsstorlek + ' kg';
                self.setState({
                    active: product.Aktiv,
                    itemnr: product.Nummer,
                    name: product.Benämning,
                    category: product.kategori,
                    otherUnit: (product.Pris_per_styck)? 'st' : 'kg',
                    pricePerOne: (product.PrisFinns)? product.Pris : 'Inget pris',
                    priceParcel: (product.PrisFinns)? product.Pris * sizeofParcel: 'Inget pris',
                    extraChargeOnSmallUnit: (product.Ej_delbar)? 'Ej delbar': product.DelatPåslag +' %',
                    unitsize: sizeofParcel,
                    sizeOptions: product.Stegom, 
                    canBuyPieces: product.Pris_per_styck,
                    sizeOfParcel: sizeofParcelShow,
                    origin: product.Ursprungsland,
                    vat: product.moms.toString(),
                    info: product.URL_Prodinfo,
                    URL_img: product.URL_Bild,
                    fav: product.favorit,
                    jmfr_pris: product.jmfr_pris,
                    priceExists: product.PrisFinns,
                    priceRelText: product.PrisText,
                    notDividable: product.Ej_Delbar,
                    hasLoaded: true,
                    beskrivning: product.Beskrivning,
                    innehållsförteckning: product.Innehållsförteckning,
                });
            } else {
                self.setState({
                    error: <DisplayError {...response.error} />
                });
            }
        });
    }

    informAboutAddedProduct(alreadyExists, name, itemnr) {
        let message = <div>Notera: Varan <b>{name}</b>({itemnr}) finns redan i din varukorg</div>
        this.setState({
            showInfoBox: alreadyExists,
            infoMessage: message
        })
        
    }
    setModal(value) {
        this.setState({
            showErrorModal: value
        });
    }
    addProduct(product) {
        let self = this;
        axios.post('cart/add', product).then(function (response) {
            response = response.data;
            if(response.success && response.error){
                let error = response.error[0];

                self.setState({
                    showInfoBox: true,
                    infoMessage: error.title + ': ' + error.description
                });
            } 
            if (!response.success) {
                let error = response.error[0];
                self.setState({
                    showErrorModal: true,
                    error: error
                });
            }

            self.props.updateCart();
            //self.informAboutAddedProduct(response.data.productAlreadyExists, name, itemnr);
        });
        
       
    }
    updateFav(productID, isFav) {
        let self = this;
        if(isFav > 0) {
            axios.delete('product/user/favorite/row/delete',  {data: {ProduktID: productID}}).then(function (response) {
                self.update();
            });
        } else {
            axios.post('product/user/favorite/row/add', {ProduktID: productID}).then(function (response) {
                self.update();
            });
        }
    }
    setCategory(category) {
        history.push('/index', {id: category.categoryID, name: category.categoryName, filter: this.state.filter})
    }

    setFilter(temp) {
        this.setState({
            filter: temp
        })
    }

    render() {
        let infoDiv; 
        if(this.state.showInfoBox) {
            infoDiv = < div className="alert alert-primary" role="alert" >
                        {this.state.infoMessage}
                     </div>
        }
        let image = '';
        if(this.state.URL_img === null) {
            image = '/img/logo.png';
        } else if(this.state.URL_img !== null && this.state.URL_img.length > 0)  {
            image = 'https://bilder.gronsakshuset.se/'+this.state.URL_img;
        }

        let orderform = <p>Artikeln är stängd och går inte att beställa </p>;
        if(this.state.active) {
            orderform =  <div className="row pb-3">
                            <div className="col-auto d-flex align-items-center">
                                <FavButton isFav={this.state.fav} makeFav={(e) => { e.preventDefault(); this.updateFav(this.props.productID, this.state.fav)}}/>
                            </div>
                            <div className="col">
                                <AmountForm productID={this.props.productID} canBuyPieces={this.state.canBuyPieces} unitsize={this.state.unitsize} sizeOptions={this.state.sizeOptions} notDividable={this.state.notDividable} addProduct={this.addProduct} />
                            </div>
                        </div>;
        } 

        if(!this.state.priceExists) {
            orderform =  <div className="row pb-3">
                            <div className="col-auto d-flex align-items-center">
                                <FavButton isFav={this.state.fav} makeFav={(e) => { e.preventDefault(); this.updateFav(this.props.productID, this.state.fav) }}/>
                            </div>
                            <div className="col amountform">
                                <p>{this.state.priceRelText}</p>
                            </div>
                        </div>;
        } 
        let priceUnit = ''
        if(this.state.priceExists) {
            priceUnit = <p>kr/{this.state.otherUnit }</p>;
        }
        

        return(
            <div className="row">
                {/*<Categories setCategory={this.setCategory} getFilter={this.setFilter} favsLoaded={this.state.hasLoaded}/>*/}
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="pb-2">{ this.state.name }</h2>
                            { this.state.error }
                            { infoDiv }
                            {
                                this.state.showErrorModal &&
                                <Modal outsideClickCloses={false} setModal={(value) => this.setModal(value)}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12">
                                                <h2>{this.state.error.title}</h2>
                                                <p>{this.state.error.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <p>{ this.state.beskrivning }</p>
                                </div>
                            </div>
                            {orderform}
                            
                            <div className="row">
                                <div className="col-md-12">
                                    <table className="table table-striped ">
                                        <tbody>
                                        <tr><th>Artikelnummer</th><td>{ this.state.itemnr }</td></tr>
                                        <tr><th>Namn</th><td>{ this.state.name }</td></tr>
                                        <tr><th>Kategori</th><td>{ this.state.category }</td></tr>
                                        <tr><th>Pris per kolli</th><td>{ this.state.priceParcel?.toFixed(2) } {(this.state.priceExists)? 'kr' : ''}</td></tr>
                                        <tr><th>Pris per kg/st</th><td>{ this.state.pricePerOne } {priceUnit}</td></tr>
                                        <tr><th>Förpackningstorlek</th><td>{ this.state.sizeOfParcel }</td></tr>
                                        <tr><th>Jämförelsepris</th><td>{ this.state.jmfr_pris}</td></tr>
                                        <tr><th>Delat påslag</th><td>{ this.state.extraChargeOnSmallUnit }</td></tr>
                                        <tr><th>Ursprungsland</th><td>{ this.state.origin }</td></tr>
                                        <tr><th>Momsklass</th><td>{ this.state.vat }</td></tr>
                                        <tr><th>Innehållsförteckning</th><td>{ this.state.innehållsförteckning }</td></tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-4 col-md-12">
                            <a href={image} download><img src={image} alt={ this.state.name } style={{width: '25rem', maxWidth: '290px', height: '26rem', objectFit: 'scale-down'}}/></a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ViewProduct;
