import React from 'react';
import SearchBar from './SearchBar.js';
import axios from '../bin/axios_gsh';

class ProductSearchBar extends SearchBar{
    constructor(props) {
        super(props);
        this.state = {
            searchString: "",
            updateResults: false,
        }
    }
    componentDidUpdate(prevProps) {
        

        if(this.props.refresh) {
            this.manualSearch(true);
        }
    }

    search() {
        
    }

    manualSearch(refresh) {
        let self = this,
        str = this.state.searchString;

        let info = {
            searchString: this.state.searchString,
            filter: this.props.filter
        };
        
        if(str && str !== "" && str.length > 1) {
            if(!refresh) {
                this.props.beginSearch();
            }

            axios.post('product/search/main/', info).then(function (response) {
                response = response.data;
                let arr = [];
                response.forEach(element => {

                    let productInfo = {
                        productID: element.ProduktID,
                        itemnr: element.Nummer,
                        categoryID: element.ID_Kategori, 
                        weightPerPackage: element.Förpackningsstorlek, 
                        sizeOptions: element.Stegom, 
                        name: element.Benämning, 
                        fav: (element.favorit)? 1 : 0, 
                        origin: element.Landskod, 
                        weightPerUnit: element.Vikt_per_enhet, 
                        priceForOne:  (element.Pris !== null)? (element.Pris).toFixed(2).replace('.', ',') : null, 
                        notDividable: element.Ej_Delbar, 
                        priceExits: element.PrisFinns,
                        jmfr_pris: element.jmfr_pris,
                        priceRelText: element.PrisText,
                        vat: element.moms, 
                        canBuyPieces: element.Pris_per_styck, 
                        URL_img: element.URL_Bild,
                        selection: element.Avtalsartikel
                    };

                    arr.push(productInfo);
                    
                });
                
                self.setState({
                    data: arr,
                }, () => {
                    self.updateList()
                });          
            });
        }
    }

    clickSearch(e) {
        e.preventDefault();

        this.manualSearch();
    }

    updateList() {
        let searchResult = [],
        i = 0;
        
        if(this.state.data !== null && this.state.data.length > 0) {
            let sorted = [],
            searchString = (this.state.searchString)? this.state.searchString.toLowerCase() : "",
            inString = 0;

            this.state.data.forEach(product => {
                let name = ((product.name).toLowerCase());

                inString = (name.search(searchString));
                if(inString === -1) {
                    inString = 999;
                }
                sorted.push({ index: i, product: product, priority: inString });
                i++;
            });

            sorted.sort(function(a, b) {
                let ak = a.priority,
                    bk = b.priority;
                if(ak < bk) return -1;
                if(ak > bk) return 1;
                return 0;
            });

            sorted.forEach(object => {
                searchResult.push(object.product);
            });

        }
        this.props.onSearch(searchResult)
    }

    render() {
        

        return(
            <form onSubmit={this.clickSearch}>
                <div className="mb-3 search-wrapper row no-gutters">
                    <div className="input-group input-group-lg">
                        <input type="text" className="form-control rounded-0" name="search" id="search" placeholder="Sök efter en artikel" value={ this.state.searchString } onChange={ this.handleChange } disabled={ this.state.disabled } autoComplete="off"/>
                        <div className="input-group-append">
                            {this.props.children}
                            <button style={{backgroundSize: '38px 38px'}} type="submit" rel="tooltip" data-toggle="tooltip" data-placement="bottom" title='Sök' className="btn btn-svg search-products icon lg btn-outline-primary btn-lg">
                            </button>
                        </div>
                    </div>
                </div>   
            </form>
        );
    }
}

export default ProductSearchBar;