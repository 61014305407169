import React, { Component } from 'react';
import {Router, Route, Switch, Redirect} from 'react-router-dom';
import history from './components/BrowserHistory';
import axios from './bin/axios_gsh';

import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.css';
import './css/master.css';

import LoginForm from './LoginForm';

import NavSide from './components/NavSide';
import NavTop from './components/NavTop';
import Order from './Order';
import OrderTemplate from './OrderTemplate';
import Stock from './Stock';
import Cart from './Cart';
import SideCart from './components/SideCart';
import Modal from './components/Modal.js';


import ProductFavorite from './ProductFavorite';
import ViewProduct from './ViewProduct';

import Orders from './Orders';
import OrderSaved from './OrderSaved';
import AccountDetails from './AccountDetails';
import ApprovedOrder from './ApprovedOrder';
import { withCookies } from 'react-cookie';

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: null,
            loggedIn: false,
            initialResponse: false,

            showSideCart: false,
            dbAndCartNotMatching: false, 
            openModal: false,
            logout: false,
            failedInserts: [],
            showImg: false,

            perPage: 50,
            hasLoaded: false,
            authenticating: false,

            error: null,
            showErrorModal: false,
            showDateModal: false,
            showRemovedProductModal: false,

            deliveryDate: null,
            removedProducts: [],
            cart: [],
            totalPrice: 0,
            totalPriceVAT: 0,
            filter: [
                {name: 'Eko', checked: false},
                {name: 'Krav', checked: false}, 
                {name: 'Närodlat', checked: false},
                {name: 'Svenskt', checked: false},
                {name: 'Avtalsartiklar', checked: false}, 
            ],
        };
        /*this.auth = this.auth.bind(this);*/
        this.setLogin = this.setLogin.bind(this);
        this.setShowImg = this.setShowImg.bind(this);

        this.Home = this.Home.bind(this);
        this.Login = this.Login.bind(this);
        this.TopNav = this.TopNav.bind(this);
        this.SideNav = this.SideNav.bind(this);
        this.Account = this.Account.bind(this);
        this.SavedOrder = this.SavedOrder.bind(this);
        this.ViewProduct = this.ViewProduct.bind(this);
        this.OrdersSaved = this.OrdersSaved.bind(this);
        this.Cart = this.Cart.bind(this);
        this.PlacedOrders = this.PlacedOrders.bind(this);
        this.ApprovedOrder = this.ApprovedOrder.bind(this);
        this.SpecificOrder = this.SpecificOrder.bind(this);
        this.FavoriteProducts = this.FavoriteProducts.bind(this);
        this.StockView = this.StockView.bind(this);

        this.loadPage = this.loadPage.bind(this);
        this.setFilter = this.setFilter.bind(this);
        this.updateCart = this.updateCart.bind(this);
        this.setModal = this.setModal.bind(this);
        this.emptyCart = this.emptyCart.bind(this);
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.location !== prevProps.location) {
        }

        
        if(!this.state.authenticating) {
            //this.authenticate();
        }
        

        if(this.state.initialResponse !== prevState.initialResponse && this.state.initialResponse === true) {
            this.loadPage();
        }
    }

    componentDidMount() {
        let self = this;
        this.interval = setInterval(() => this.authenticate(), 15000);
        this.loadPage();

        ( axios.get('auth/loggedIn').then(function (loggedInResponse) {
            if(loggedInResponse.data.loggedIn === true) {
                ( axios.get('auth/csrf').then(function (response) {
                    let result = response.data;
                    if(result.csrfToken !== null) {
                        let axiosDefaults = axios.defaults;
                        window.__csrf_token = result.csrfToken;
                        axiosDefaults.headers.common['X-CSRF-Token'] = result.csrfToken;
                    }
                    self.setState({
                        loggedIn: true,
                        initialResponse: true,
                    }, () => {
                        self.updateCart();
                    });
                }));
            }else{
                self.setState({
                    loggedIn: false,
                    initialResponse: true,
                });
            }
        })).catch((error) => {
            self.setState({
                loggedIn: false,
                initialResponse: true,
            });
        });
    }

    async updateCart(){
        let self = this;
        ( axios.get('cart').then(function (response) {
            let cart = response.data;
            
            if(!cart.error){
                if(cart.removedProducts.length > 0 && self.state.removedProducts.length === 0){
                    cart.showRemovedProductModal = true;
                }else {
                    cart.removedProducts = self.state.removedProducts;
                }
    
                if(self.state.deliveryDate !== null && cart.deliveryDate !== self.state.deliveryDate){
                    //cart.showDateModal = true;
                    // What do
                }
            } else {
                cart.showErrorModal = true;
            }

            //cart.removedProducts = [{Benämning: 'Ananas', Nummer: 'asdasdewrewr'}]; 
            // Test row ^
            self.setState(
                cart
            );
        }));
    }

    loadPage() {
        let self = this;
        
        let loggedOutState = {
            user: null,
            logout: false,
            hasLoaded: true,
        };

        if(this.state.loggedIn && this.state.initialResponse) {
            //this.authenticate();
            ( axios.get('user/current').then(function (response) {
                let user = response.data;

                if(!response.error) {
                    self.setState({
                        user: {
                            userid: user.ID,
                            login: user.Login,
                            name: user.Namn,
                            email: user.Email,
                            companyID: user.ID_Kund,
                            company: user.Företag,
                        },
                        logout: false,
                        hasLoaded: true,
                    }, () => {
                        
                    });
                } else {
                    self.setState(loggedOutState);
                }
            })).catch(function (error) {
                self.setState(loggedOutState);
            });
        }else{
            self.setState(loggedOutState);
        }
    }
    setFilter(filter) {
        this.setState({
            filter: filter,
        });
    }

    setShowImg() {
        this.setState(state => ({
            showImg: !state.showImg
        }));
    }

    saveAllAgain() {
        this.setState({
            openModal: false,
            failedInserts: []
        });
    }
    
    showSideCart() {
        this.setState({
            showSideCart: true
        });
    }
    
    setLogin(user) {
        this.setState({
            user: user,
        });
    }
    authenticate() {
        let self = this;
        if(this.state.user !== null) {
            if(!this.state.authenticating){
                this.setState({
                        authenticating: true
                    }, () => {
                        ( axios.get('auth/loggedIn').then(function (response) {
                            let result = response.data;
                            if(result.loggedIn === false) {
                                self.setState({
                                    user: null,
                                    logout: true,
                                    authenticating: false
                                }); 
                            }else {
                                self.setState({
                                    authenticating: false
                                });
                            }

                            if(result.newDeliveryDate) {
                                self.updateCart();
                            }
                        }));
                    });
                }
        }
    }
    

    Home() {
        let self = this;
        let setPerPage = function(perPage) {
            self.setState({
                perPage: perPage
            });
        };

        if(this.state.user === null) {
            return <Redirect to="/login"/>;
        }else{
            return <Stock view={'all'} categoryID={null} perPage={this.state.perPage} setPerPage={setPerPage} showImg={this.state.showImg} changeImgState={this.setShowImg} filter={this.state.filter} updateCart={self.updateCart}/>;
        }
    }
    
    Login() {
        let self = this;
        
        let setLogin = (user) => {
            if(user !== null) {
                self.loadPage();
            }
            self.setState({
                user: user
            });
        };

        
        if(this.state.user === null) {
            return <LoginForm setLogin={ setLogin } updateCart={this.updateCart} />;
        }else{
            return <Redirect to="/"/>;
        }
    }
    OrdersSaved() {
        return <OrderSaved updateCart={this.updateCart}/>;
    };
    PlacedOrders() {
        return <Orders />;
    };
    SavedOrder({ match }) {
        return <OrderTemplate id={ match.params.id } updateCart={this.updateCart} />;
    };
    SpecificOrder({ match }) {
        return <Order id={ match.params.id } skipDataColumns={[0]} />;
    };
    ViewProduct({match}) {
        return <ViewProduct productID={match.params.productid}  updateCart={this.updateCart}/>;
    }
    FavoriteProducts() {
        return <ProductFavorite updateCart={this.updateCart}/>;
    };
    Account() {
        return <AccountDetails user={ this.state.user } />;
    }
    Cart() {
        return <Cart cart={this.state.cart} updateCart={this.updateCart} totalPrice={this.state.totalPrice} totalPriceVAT={this.state.totalPriceVAT} deliveryDate={this.state.deliveryDate}/>;
    }
    StockView({match}) {
        let self = this;
        let view = (match.params.view)? match.params.view : 'all';
        let categoryID = (match.params.categoryID)? match.params.categoryID : null;
        let page = (match.params.page)? match.params.page : null;

        let setPerPage = function(perPage) {
            self.setState({
                perPage: perPage
            });
        }
        if(view === 'category' && categoryID === null) {
            return <Redirect to="/stock/all"/>;
        }
        return <Stock view={view} categoryID={categoryID} page={page} perPage={this.state.perPage} setPerPage={setPerPage} showImg={this.state.showImg} changeImgState={this.setShowImg} filter={this.state.filter} updateCart={self.updateCart}/> 
    }
    SideNav({match}) {
        let view = (match.params.view)? match.params.view : 'all';
        let categoryID = (match.params.categoryID)? match.params.categoryID : null;

        return <NavSide view={view} categoryID={categoryID} setFilter={this.setFilter} user={this.state.user} filter={this.state.filter}/>;
    }
    ApprovedOrder({match}) {
        return <ApprovedOrder orderID={match.params.orderID}/>;
    }

    TopNav({match}) {
        return <NavTop sitepage={match.params.page} setLogin={ this.setLogin } user={this.state.user} logout={this.state.logout} totalPrice={this.state.totalPrice} deliveryDate={this.state.deliveryDate}/>;
    }

    setModal(modal, value){
        switch(modal) {
            case 'dateModal':
                this.setState({
                    showDateModal: value
                });
                break;
            case 'removedProductModal':
                if(value){
                    this.setState({
                        showRemovedProductModal: value,
                        removedProducts: []
                    });
                } if(!value){
                    this.setState({
                        showRemovedProductModal: value,
                    });
                }
                break;
            case 'errorModal':
                this.setState({
                    showErrorModal: value,
                });
                break;
            default:
                // Something went wrong
                break;
        }
    }

    emptyCart() {
        let self = this;
        ( axios.get('cart/clear').then(function (response) {
            self.setModal('errorModal', false);
        }));
    }

    render() {
        let button = <button onClick={ this.saveAllAgain } type="button" className="btn btn-primary" data-dismiss="modal">Ja, spara</button>
       
        
        let sidecart = '';
        if(this.state.user !== null) {
           sidecart = <SideCart showCart={this.state.showSideCart} updateCart={this.updateCart} cart={this.state.cart}/>
        };

        let emptyCart = <button onClick={ this.emptyCart } type="button" className="btn btn-danger" data-dismiss="modal">Töm varukorgen</button>
        return (
            <Router history={history}>
            <div>
            {
                (!this.state.initialResponse || this.state.user === null)?
                    <div className="container-fluid px-0">
                        <div className="row no-gutters">
                            <div className="col-xl col-lg-12 ">
                                <div className="px-3 substitute-height">
                                    <Route path="/">
                                        <Switch>
                                            <Route path="/:page" component={ this.TopNav }></Route>
                                            <Route path="" component={ this.TopNav }></Route>
                                        </Switch>
                                    </Route>
                                </div>
                                <div className="py-3 px-3 cover-height">
                                    <div className="row">
                                        <div className="col-xl col-lg-12">
                                            <div className="row d-flex justify-content-center pt-3">
                                                <div className="col-12 col-xl-12">
                                                    <Route component={ this.Login }></Route>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="px-3">
                                    <div className="row">
                                        <div className="col-md-12 py-5 footer">
                                            Grönsakshuset - Tel: 0500-471665
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="container-fluid px-0">
                        <div className="row no-gutters">
                            <div className="col-xl col-lg-12 ">
                                <div className="p-3 substitute-height">
                                    <Route path="/">
                                        <Switch>
                                            <Route path="/:page" component={ this.TopNav }></Route>
                                            <Route path="" component={ this.TopNav }></Route>
                                        </Switch>
                                    </Route>    
                                    {sidecart}
                                </div>
                                <div className="py-3 px-3 cover-height">
                                    <div className="row">
                                        {
                                            (this.state.user?
                                            <div className="col-auto">
                                                <Route path="/" >
                                                    <Switch>
                                                        <Route path="/stock/:view">
                                                            <Switch>
                                                                <Route path="/stock/:view/:categoryID" component={ this.SideNav }></Route>
                                                                <Route component={ this.SideNav }></Route>
                                                            </Switch>
                                                        </Route>
                                                        <Route component={ this.SideNav }></Route>

                                                        {/*
                                                        <Route path="/stock/cat/:categoryID" component={ this.SideNav }></Route>
                                                        <Route exact path="/stock/agreement" component={ this.SideNav }></Route>
                                                        <Route exact path="/stock" component={ this.SideNavCampaign }></Route>
                                                        */}
                                                    </Switch>
                                                </Route>
                                            </div>
                                            : '')
                                        }
                                        <div className="col-xl col-lg-12 pt-3">
                                            <div className="row d-flex justify-content-center">
                                                <div className="col-12 col-xl-12">
                                                    <Route path="/" >
                                                        <Switch>
                                                            <Route exact path="/index" component={ this.Home } />
                                                            <Route exact path="/login" component={ this.Login }/>
                                        
                                                            <Route path="/stock/:view">
                                                                <Switch>
                                                                    <Route path="/stock/:view/:categoryID" component={ this.StockView }/>
                                                                    <Route component={ this.StockView }/>
                                                                </Switch>
                                                            </Route>
                                                            <Route exact path="/product/:productid" component={ this.ViewProduct }/>

                                                            <Route exact path="/lists" component={ this.OrdersSaved }/>
                                                            <Route path="/lists/list/:id" component={ this.SavedOrder }/>

                                                            <Route path="/order/:id" component={ this.SpecificOrder }/>
                                                  
                                                            <Route exact path="/orders" component={ this.PlacedOrders }/>
                                        
                                                            {/* <Route exact path="/order" component={ this.OrdersSaved }/> */}
                                                            <Route exact path="/favorites" component={ this.FavoriteProducts }/>

                                                            <Route path="/account" component={ this.Account }/>


                                                            <Route exact path="/order" component={ this.Cart }/>
                                                            <Route exact path="/approved/:orderID" component={ this.ApprovedOrder} />

                                                            <Redirect to="/index"/>
                                                        </Switch>
                                                    </Route>
                                                </div>
                                            </div>
                                            {
                                                (this.state.showRemovedProductModal) && 
                                                <Modal outsideClickCloses={false} setModal={(value) => this.setModal('removedProductModal', value)} closeButtonText={"Ok"}>
                                                    <div className="container">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <h2>Ett fel har skett</h2>
                                                                <p>Din gamla varukorg kunde inte läsas in ordentligt. Detta beror på att sortimentet har uppdaterats. Följande artiklar togs ur din varukorg:</p>
                                                                <ul>
                                                                    {this.state.removedProducts.map((x) => <li>{ x.Nummer + ": " + x.Benämning }</li>)}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Modal>
                                            }
                                            {
                                                (this.state.showDateModal) && 
                                                <Modal outsideClickCloses={false} setModal={(value) => this.setModal('dateModal', value)} closeButtonText={"Ok"}>
                                                    <div className="container">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <h2>Utgånget leveransdatum</h2>
                                                                <p>Det aktuella leveransdatumed uppdaterades.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Modal>
                                            }
                                            {
                                                this.state.showErrorModal &&
                                                <Modal outsideClickCloses={false} extraButton={emptyCart} setModal={(value) => this.setModal('errorModal', value)}>
                                                    <div className="container">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <h2>{this.state.error.title}</h2>
                                                                <p>{this.state.error.description}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Modal>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="px-3">
                                    <div className="row">
                                        <div className="col-md-12 py-5 footer">
                                            Grönsakshuset - Tel: 0500-471665
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                    { this.state.component }
                </div>
            </Router>
        );
    }
}

export default withCookies(App);

