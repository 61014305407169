import React from 'react';
import { Link } from 'react-router-dom';


class DiscountedProduct extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            productID: this.props.productID,
            URL_img: this.props.data.URL_img
        }
    }
   
    render() {
        let image = 'https://bilder.gronsakshuset.se/' + this.props.data.URL_img;
        if(typeof(this.state.URL_img) === undefined || this.state.URL_img === null || this.state.URL_img.length <= 0) {
            image = '/../img/logo.png';
        }
        let unit = (this.props.data.canBuyPieces)? 'kr/st' : 'kr/kg'
        
        return(
            <div className="col-md-2 mt-4">
                
                <Link to={"product/"+this.props.data.productID}>
                <div className="card">
                    <div className="card-header header-wrapper">
                        <div className="header-content">
                            <h5>{this.props.data.name}</h5>
                        </div>
                    </div>
                    <div className="d-flex align-items-center min-height">
                        <img className={image.includes('logo.png') ? "card-img-bottom" : "card-img-top"} src={image} alt={this.props.data.name}/>
                    </div>
                    <h5 className="card-title d-flex justify-content-end mb-0">
                        <span className="badge badge-primary" >Pris: {this.props.data.priceForOne} {unit}</span>
                    </h5>
                </div>
                </Link>
            </div>
        );
    }
}

export default DiscountedProduct;
